.Terms{
    background-color: #fff;
}
.Terms-main{
    box-sizing: border-box;
    width: 750px;
    margin: 0 auto;
    padding-top: 40px;
}

.title{
    height: 20px;
    font-size: 23px;
    font-weight: 500;
    color: #000;
    margin-bottom: 23px;
}

p{
    color: #000;
    font-size: 16px;
}